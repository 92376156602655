import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import styled from "styled-components";


const AddToCartButton = ({ product, selectedVariation }) => {
  const { cart, addToCart } = useContext(CartContext);
  
  const navigate = useNavigate();

  const [cartItem, setCartItem] = useState(null);

  // Reactive behavior: Find if the current product/variation is in the cart
  useEffect(() => {
    const foundItem = product.isVariable
      ? cart.find(
          (item) =>
            item.product._id === product._id &&
            item.selectedVariation?.size === selectedVariation?.size
        )
      : cart.find((item) => item.product._id === product._id);

    setCartItem(foundItem);
  }, [cart, product, selectedVariation]);

  // Add the product/variation to the cart
  const handleAddToCart = () => {
    console.log(selectedVariation);
    if (product.isVariable && !selectedVariation) {
      alert("Please select a variation before adding to the cart.");
      return;
    }

    addToCart(product, selectedVariation);
  };

  // Navigation handlers
  const goToCart = () => navigate("/cart");
  const goToCheckout = () => navigate("/checkout");
  const AddToCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const Actions = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &.btn-cart {
    background-color: #4caf50;
    color: white;
  }

  &.btn-checkout {
    background-color: #ff9800;
    color: white;
  }

  &.btn-add {
    background-color: #007bff;
    color: white;
  }
`;

return (
  <AddToCartContainer>
    {cartItem ? (
      <>
        <p>
          <strong>{cartItem.quantity}</strong> in cart
        </p>
        <Actions>
          <Button className="btn-cart" onClick={goToCart}>
            Go to Cart
          </Button>
          <Button className="btn-checkout" onClick={goToCheckout}>
            Checkout
          </Button>
        </Actions>
      </>
    ) : (
      <Button className="btn-add" onClick={handleAddToCart}>
        Add to Cart
      </Button>
    )}
  </AddToCartContainer>
);
  
};

export default AddToCartButton;
