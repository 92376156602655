import React, { useState } from "react";
import { Link } from "react-router-dom";
import CategoryDropdown from './CategoryDropdown';

function Header({ cart }) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="bg-blue-600 text-white p-4">
      <nav className="flex justify-between items-center">
        <h1 className="text-2xl">
          <Link to="/">ProPrimeLabs</Link>
        </h1>
        <div className="md:hidden">
          {/* Hamburger Menu */}
          <button
            className="text-white focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <ul
          className={`md:flex space-x-4 ${
            menuOpen ? "block" : "hidden"
          } absolute md:relative md:block bg-blue-600 w-full md:w-auto top-14 md:top-auto left-0`}
        >
          <li>
            <Link to="/" className="block p-2 hover:underline">
              Home
            </Link>
          </li>
          <li>
            <Link to="/account" className="block p-2 hover:underline">
              Account
            </Link>
          </li>
          <li>
            <Link to="/about-us" className="block p-2 hover:underline">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/contact-us" className="block p-2 hover:underline">
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/shop" className="block p-2 hover:underline">
              Shop
            </Link>
          </li>
          <li><CategoryDropdown /></li>
          <li>
            <Link to="/cart" className="block p-2 hover:underline">
              Cart ({cart.length})
            </Link>
          </li>
          <li>
            <Link to="/checkout" className="block p-2 hover:underline">
              Checkout
            </Link>
          </li>
          <li>
            <Link to="/track_order" className="block p-2 hover:underline">
              Track Order
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
