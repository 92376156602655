import React, { useContext } from 'react';
import { Link, Route, Routes,Navigate ,useParams} from 'react-router-dom';
import './App.css';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import { CartContext } from './context/CartContext';
import CheckoutPageSuccess from "./pages/CheckoutPageSuccess";
import TrackOrder from './pages/TrackOrder';
import ProductDetails from './components/ProductDetails';
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Shop from "./pages/Shop";
import Homepage from './pages/Homepage';
import CategoryPage from "./pages/CategoryPage";
import {HelmetProvider} from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';

const PageNotFound = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <Link to="/" style={{ color: 'blue', textDecoration: 'underline' }}>
      Go back to Home
    </Link>
  </div>
);

const productIdToSlugMap = {
  '677ac5ea35c34d2aa47b69a8': 'penis-envy-mushroom-spores',
  '677ac46635c34d2aa47b69a5': 'golden-teacher-mushroom-spores',
  '6768212b7edb0e491d5cc421': 'golden-teacher-mushroom-spores'
};

function ProductRedirect() {
  const { id } = useParams();

  // Find the new slug based on the product ID
  const slug = productIdToSlugMap[id];

  // If no mapping exists, show "Page Not Found"
  if (!slug) {
    return <div>Product Not Found</div>;
  }

  // Redirect to the new URL
  return <Navigate to={`/en/products/${slug}`} replace />;
}

function App() {
  const { cart } = useContext(CartContext);

  return (
    <div className="container mx-auto">
      {/* Responsive Header */}
      <Header cart={cart}/>

      {/* Main Content */}
      <main>
      <HelmetProvider>

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/cart" element={<CartPage cart={cart} />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/checkoutpagesuccess" element={<CheckoutPageSuccess />} />
          <Route path="/track_order" element={<TrackOrder />} />
          <Route path="/:store/products/:slug" element={<ProductDetails />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/:store/collections/:slug" element={<CategoryPage />} />
          

          <Route path="/product/:id" element={<ProductRedirect />} />

       
          <Route path="*" element={<PageNotFound />} />
 

        </Routes>
        </HelmetProvider>
      </main>

      {/* Footer */}
      <Footer cart={cart}/>
    </div>
  );
}

export default App;
