import React, { useState } from "react";
import { Link } from "react-router-dom";
import CategoryDropdown from './CategoryDropdown';

function Footer({cart}){
return(

    <footer className="bg-gray-800 text-white p-4 mt-8">
    <div className="container mx-auto text-center">
      <p>&copy; {new Date().getFullYear()} Pro Prime Labs [ New york,New york]</p>
      <p>All products sold on this website are for research purposes only.</p>
      <p>You must be 21+  to use our services.</p>
      <ul className="flex justify-center space-x-4 mt-4">
        <li><Link to="/" className="text-blue-400 hover:underline">Home</Link></li>
        <li><Link to="/about-us" className="text-blue-400 hover:underline">About Us</Link></li>
        <li><Link to="/contact-us" className="text-blue-400 hover:underline">Contact Us</Link></li>
        <li><Link to="/shop" className="text-blue-400 hover:underline">Shop</Link></li>
        <li><CategoryDropdown /></li>
        <li><Link to="/cart" className="text-blue-400 hover:underline">Cart ({cart.length})</Link></li>
        <li><Link to="/checkout" className="text-blue-400 hover:underline">Checkout</Link></li>
        <li><Link to="/track_order" className="text-blue-400 hover:underline">Track Order</Link></li>
        
      </ul>
    </div>
  </footer>
);
}
export default Footer;