import React, { useEffect, useState } from "react";
import ProductList from "./ProductList";
import { Helmet } from 'react-helmet-async';

const RelatedProducts = ({ product }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        if (!product?._id) {
          setLoading(false);
          return;
        }

        const response = await fetch(
          `https://supplement-app-d151447d38d4.herokuapp.com/api/related_products/${product._id}`
        );
        
        if (!response.ok) throw new Error('Failed to fetch related products');
        
        const data = await response.json();
        
        // Use the relatedProducts from the API response
        setRelatedProducts(data.relatedProducts || []);
        
      } catch (error) {
        console.error("Error fetching related products:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedProducts();
  }, [product?._id]); // Only re-run when product ID changes

  if (loading) {
    return <div className="text-center mt-4">Loading related products...</div>;
  }

  if (error) {
    return <div className="text-center mt-4 text-red-500">{error}</div>;
  }

  if (!relatedProducts.length) return null;

  return (
    <div className="related-products mt-12">
      <Helmet>
        <title>Related Products for {product.name}</title>
      </Helmet>
      
      <h3 className="text-2xl font-semibold mb-6 border-b pb-2">
        Related Products
      </h3>
      <ProductList 
        products={relatedProducts}
      />
    </div>
  );
};

export default RelatedProducts;