import React, { useEffect, useState } from "react";
import ProductList from "../components/ProductList";
import CategoryList from "../components/CategoryList";
import BestSellerProducts from "../components/BestSellerProducts";
import { Helmet } from 'react-helmet-async';
import { useNavigate,Link } from "react-router-dom";

const Homepage = () => {
  const [products, setProducts] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null); // Track the active accordion
  const navigate = useNavigate();
  const heroImage = products.length > 0 ? products[0].picture : "";
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          "https://supplement-app-d151447d38d4.herokuapp.com/api/products/"
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Function to toggle the accordion
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="homepage">
      
<Helmet>
                <title>Pro Prime Labs: Order magic mushroom spores,whey protein,research chemicals|Free shipping in USA</title>
                <meta name="description" content="Pro Prime Labs has a huge collection of the best magic mushroom spores, Whey Protein,Pure creatine,BCAA muscle catalyst and research chemicals."/>
            </Helmet>
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[400px]"
        style={{
          backgroundImage: `url("${heroImage}")`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-center">
          <div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Welcome to Pro Prime Labs
            </h1>
            <p className="text-lg md:text-xl text-white">
              We offer you a broad range of  the best magic mushroom spores, testosterone boosters, research chemicals whey protein,pure creatine to help you relax, achieve your ultimate aesthetics and strength.
            </p>
            <button
              className="mt-6 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
              onClick={() => navigate("/shop")}
            >
              Shop Now
            </button>
          </div>
        </div>
      </section>

      {/* Promotional Section */}
      <section className="py-16 bg-gray-100 text-center">
        <h2 className="text-3xl font-bold mb-6">What is our mission?</h2>
        <div className="flex flex-wrap justify-center space-x-6">
          <div className="w-full md:w-1/3 p-4 bg-white shadow rounded">
            <h3 className="text-xl font-semibold">Pro Prime Labs is here for your Magic mushroom spores, whey protein supplements and other research chemicals</h3>
            <p className="text-gray-600">
              Order magic mushroom spores.
            </p>
          </div>
          <div className="w-full md:w-1/3 p-4 bg-white shadow rounded">
            <h3 className="text-xl font-semibold">Infinite Prime</h3>
            <p className="text-gray-600">
              We have a huge collection of Magic mushroom spores,research chemicals and whey protein supplements.
            </p>
          </div>
          <div className="w-full md:w-1/3 p-4 bg-white shadow rounded">
            <h3 className="text-xl font-semibold">Balance and stability</h3>
            <p className="text-gray-600">
              Our magic mushroom spores,testosterone boosters, whey protein,research chemicals and creatine are manufactired in our state-of-the art labs.
            </p>
          </div>
        </div>
      </section>
      <CategoryList/>
      <BestSellerProducts />
      {/* FAQ Section */}
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold text-center mb-6">New to ProPrimelabs?, Here are some FAQ</h2>
        <div>
          {[
            {
              question: "What are psilocybe cubensis mushroom spores",
              answer:
                "These are basically spores of magic mushrooms.We deliver them in syringes.",
            },
            {
              question: "what are the side-effects of testosterone boosters?",
              answer:
                "When you start taking testosterone boosters, it may temporarily limit your body's ability to produce testosterone but this will cease when you stop taking the boosters.",
            },
            {
              question: "I want to be able to do more reps and rounds what should i take?",
              answer:
                "Our pure creatine will help you workout more while our whey protein will add you an incredible amount of muscle mass",
            },
            {
              question: "Do you have a refund policy?",
              answer:
                "Yes,we have a 30-day money back refund policy.",
            },
          ].map((faq, index) => (
            <div
              key={index}
              className="border border-gray-300 rounded-md mb-4"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="flex justify-between w-full p-4 text-lg font-semibold focus:outline-none"
              >
                {faq.question}
                <span>{activeAccordion === index ? "-" : "+"}</span>
              </button>
              {activeAccordion === index && (
                <div className="p-4 text-gray-600">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Homepage;
